import React, { createContext, useState, useContext } from "react";

const CameraPermissionContext = createContext();

export function CameraPermissionProvider({ children }) {
  const [isCameraAllowed, setIsCameraAllowed] = useState(false);

  const setCameraPermission = (v) => {
    setIsCameraAllowed(v);
  };

  return (
    <CameraPermissionContext.Provider value={{ isCameraAllowed, setCameraPermission }}>
      {children}
    </CameraPermissionContext.Provider>
  );
}

export function useCameraPermission() {
  return useContext(CameraPermissionContext);
}
