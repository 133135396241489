import VariablesStore from ".";
import { makeAutoObservable } from "mobx";

export default class FacetecVariableStore {
  result = "";

  constructor(private variablesStore: VariablesStore) {
    makeAutoObservable(this);
  }

  setResult = (result: any) => {
    this.result = result;
  };
}
