import { makeAutoObservable, computed } from "mobx";
import VariablesStore from ".";
import { Countries, IncomeFrequency } from "../../types";

export default class PersonVariablesStore {
  personId = "";
  personName = "";
  personCpf = "";
  personBirthDate = "";
  personMotherName = "";
  personFatherName = "";
  personEmail = "";
  personValidatedEmails: Record<string, boolean> = {};
  personPhoneNumber = "";
  personValitatedPhones: Record<string, boolean> = {};
  personRg = "";
  personNit = "";
  personLicensePlate = "";
  personRgIssuingAuthority = "";
  personRgIssueDate = "";
  personRgIssueState = "";
  personAddressStateUf = "";
  personAddressState: string | null = null;
  personAddressCity: string | null = null;
  personAttorney?: boolean | null = null;
  personAddressZipCode = "";
  personAddressStreet = "";
  personAddressNeighborhood = "";
  personAddressNumber = "";
  personAddressComplement = "";
  personAddressCountry: Countries = "BR";
  personIncome = "";
  personIncomeFrequency: IncomeFrequency = null;
  personStatesList: {
    name: string;
    uf: string;
  }[] = [];
  personCitiesList: {
    name: string;
  }[] = [];
  personOccupation = "";

  constructor(private variablesStore: VariablesStore) {
    makeAutoObservable(this, {
      isMonthlyIncome: computed,
      isAnnuallyIncome: computed,
    });
  }

  setPersonId = (personId: string) => {
    this.personId = personId;
  };
  setPersonName = (personName: string) => {
    this.personName = personName;
  };
  setPersonCpf = (personCpf: string) => {
    this.personCpf = personCpf;
  };
  setPersonBirthDate = (personBirthDate: string) => {
    this.personBirthDate = personBirthDate;
  };
  setPersonMotherName = (personMotherName: string) => {
    this.personMotherName = personMotherName;
  };
  setPersonFatherName = (personFatherName: string) => {
    this.personFatherName = personFatherName;
  };
  setPersonEmail = (personEmail: string) => {
    this.personEmail = personEmail;
  };
  setPersonValidatedEmails = (personEmail: string) => {
    this.personValidatedEmails[personEmail] = true;
  };
  setPersonPhoneNumber = (personPhoneNumber: string) => {
    this.personPhoneNumber = personPhoneNumber;
  };
  setPersonValidatedPhones = (personPhoneNumber: string) => {
    this.personValitatedPhones[personPhoneNumber] = true;
  };
  setPersonRg = (personRg: string) => {
    this.personRg = personRg;
  };
  setPersonNit = (personNit: string) => {
    this.personNit = personNit;
  };
  setPersonLicensePlate = (personLicensePlate: string) => {
    this.personLicensePlate = personLicensePlate;
  };
  setPersonRgIssuingAuthority = (personRgIssuingAuthority: string) => {
    this.personRgIssuingAuthority = personRgIssuingAuthority;
  };
  setPersonRgIssueDate = (personRgIssueDate: string) => {
    this.personRgIssueDate = personRgIssueDate;
  };
  setPersonRgIssueState = (personRgIssueState: string) => {
    this.personRgIssueState = personRgIssueState;
  };
  setPersonAddressStateUf = (personAddressStateUf: string) => {
    this.personAddressStateUf = personAddressStateUf;
  };
  setPersonAddressState = (personAddressState: PersonVariablesStore["personAddressState"]) => {
    const uf = this.personStatesList.find((state) => state?.name === personAddressState)?.uf;
    if (uf) {
      this.setPersonAddressStateUf(uf);
    }
    this.personAddressState = personAddressState;
  };
  setPersonAddressCity = (personAddressCity: PersonVariablesStore["personAddressCity"]) => {
    this.personAddressCity = personAddressCity;
  };
  setPersonAddressZipCode = (personAddressZipCode: string) => {
    this.personAddressZipCode = personAddressZipCode;
  };
  setPersonAddressStreet = (personAddressStreet: string) => {
    this.personAddressStreet = personAddressStreet;
  };
  setPersonAddressNeighborhood = (personAddressNeighborhood: string) => {
    this.personAddressNeighborhood = personAddressNeighborhood;
  };
  setPersonAddressNumber = (personAddressNumber: string) => {
    this.personAddressNumber = personAddressNumber;
  };
  setPersonAddressComplement = (personAddressComplement: string) => {
    this.personAddressComplement = personAddressComplement;
  };
  clearAddress = () => {
    this.setPersonAddressStateUf("");
    this.setPersonAddressState("");
    this.setPersonAddressCity("");
    this.setPersonAddressStreet("");
    this.setPersonAddressNeighborhood("");
    this.setPersonAddressNumber("");
    this.setPersonAddressComplement("");
  };
  setPersonAddressCountry = (personAddressCountry: Countries) => {
    this.setPersonAddressZipCode("");
    this.setPersonAddressState(null);
    this.setPersonAddressCity(null);
    this.setPersonAddressStreet("");
    this.setPersonAddressStateUf("");
    this.setPersonAddressNeighborhood("");
    this.setPersonAddressNumber("");
    this.setPersonAddressComplement("");
    this.setPersonCitiesList([]);
    this.setPersonStatesList([]);

    this.personAddressCountry = personAddressCountry;
  };
  setPersonIncome = (personIncome: string) => {
    this.personIncome = personIncome;
  };
  setPersonIncomeFrequency = (personIncomeFrequency: PersonVariablesStore["personIncomeFrequency"]) => {
    this.personIncomeFrequency = personIncomeFrequency;
  };
  setPersonStatesList = (personStatesList: PersonVariablesStore["personStatesList"]) => {
    this.personStatesList = personStatesList;
  };
  setPersonCitiesList = (personCitiesList: PersonVariablesStore["personCitiesList"]) => {
    this.personCitiesList = personCitiesList;
  };
  setPersonOccupation = (personOccupation: string) => {
    this.personOccupation = personOccupation;
  };

  setPersonAttorney = (personAttorney: PersonVariablesStore["personAttorney"]) => {
    this.personAttorney = personAttorney;
  };

  get isMonthlyIncome() {
    return this.personIncomeFrequency === "monthly";
  }
  get isAnnuallyIncome() {
    return this.personIncomeFrequency === "annually";
  }

  checkData = () => {
    const variables = this.variablesStore.getStoreProps(this);

    return Object.keys(variables).some((key) => !!variables[key]);
  };
}
